import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Modal, Spinner, Text } from 'src/components/ui'
import { GqlOpcConnectionConfigurationState } from 'src/services'
import { useClickOutside } from 'src/utility'
import { humanDate, humanTimeWithSeconds } from 'src/utility/time'
import { parseApiErrorMessage } from 'src/utility/errors'
import { useFetchNodeValuesMutation } from '../opc-ua-browse'

type Props = {
  configurationState: GqlOpcConnectionConfigurationState
  connectionId: string
}

export function OpcUaConnectionMenu({
  configurationState,
  connectionId,
}: Props): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const menuRef = useRef(null)
  const navigate = useNavigate()

  const {
    mutateAsync: fetchSpecificNodeValues,
    data,
    isLoading,
    isError,
    error,
    reset,
  } = useFetchNodeValuesMutation(connectionId)

  useClickOutside(menuRef, () => setIsMenuOpen(false))

  const handleVerifyConnection = async (): Promise<void> => {
    reset()
    await fetchSpecificNodeValues(['i=2258'])
  }

  const value = data?.[0].value
  const serverTimestamp: Date =
    value?.serverTimestamp ?? value?.value
      ? Object.values(value?.value)[0]
      : undefined

  return (
    <div className="relative flex items-center gap-xs" ref={menuRef}>
      <Button
        variant="icon"
        icon={light('ellipsis')}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && (
        <div
          className={classNames(
            'absolute right-0 top-[calc(100%+4px)] flex flex-col divide-x-0 divide-y divide-solid divide-grey-100 rounded-2xs bg-white shadow-dropdown',
            configurationState !== 'COMPLETE' && 'text-grey-100',
          )}
        >
          <div
            className={classNames(
              'flex whitespace-nowrap px-xs pt-xs pb-2xs hover:bg-grey-50 transition-all',
              configurationState === 'COMPLETE'
                ? 'cursor-pointer'
                : 'cursor-default',
            )}
            onClick={() => {
              if (configurationState === 'COMPLETE') {
                handleVerifyConnection()
                setIsModalOpen(true)
                setIsMenuOpen(false)
              }
            }}
          >
            <Text variant="description" bold>
              Test Connection
            </Text>
          </div>
          <div
            className={classNames(
              'flex whitespace-nowrap px-xs pb-xs pt-2xs hover:bg-grey-50 transition-all',
              configurationState === 'COMPLETE'
                ? 'cursor-pointer'
                : 'cursor-default',
            )}
            onClick={() => {
              if (configurationState === 'COMPLETE') navigate('browse')
            }}
          >
            <Text variant="description" bold>
              Browse Hierarchy
            </Text>
          </div>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        title={isLoading ? 'Testing Connection' : 'Testing completed'}
        closeIconSize="regular"
        footer={
          isLoading ? undefined : (
            <div className="flex justify-end gap-xs pt-xs">
              {isError && (
                <Button
                  icon={light('redo')}
                  title="Test again"
                  variant="secondary"
                  onClick={handleVerifyConnection}
                />
              )}
              <Button
                title="Close"
                variant="primary"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          )
        }
      >
        <div className="flex flex-col gap-xs py-2xs">
          {isLoading && (
            <>
              <Text>This should only take a couple of seconds</Text>
              <Spinner />
            </>
          )}
          {serverTimestamp && (
            <div className="flex items-center gap-s py-s">
              <Icon
                icon={light('circle-check')}
                className="size-3xl text-green-primary"
              />
              <div>
                <Text bold className="mb-3xs">
                  Success!
                </Text>
                <Text>Current Server Timestamp:</Text>
                <Text bold>
                  {humanDate(serverTimestamp)},{' '}
                  {humanTimeWithSeconds(serverTimestamp)}
                </Text>
              </div>
            </div>
          )}
          {isError && (
            <div className="flex max-w-[400px] items-center gap-s py-s">
              <Icon
                icon={light('circle-minus')}
                className="size-3xl text-delete-primary"
              />
              <div>
                <Text bold className="mb-3xs">
                  Unable to connect
                </Text>
                <Text variant="description">
                  {parseApiErrorMessage(error, 'Failed to connect')}
                </Text>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
