// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: time_series.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'
import { Duration } from './google/protobuf/duration'

export const protobufPackage = 'types.v1'

export enum AnomalyModelOutput {
  ANOMALY_MODEL_OUTPUT_UNSPECIFIED = 0,
  ANOMALY_MODEL_OUTPUT_TARGET = 1,
  ANOMALY_MODEL_OUTPUT_PREDICTION = 2,
  ANOMALY_MODEL_OUTPUT_ABSOLUTE_RAW_ERROR = 3,
  ANOMALY_MODEL_OUTPUT_SIGNED_RAW_ERROR = 4,
  ANOMALY_MODEL_OUTPUT_ABSOLUTE_ERROR = 5,
  ANOMALY_MODEL_OUTPUT_SIGNED_ERROR = 6,
  ANOMALY_MODEL_OUTPUT_SHORT_RAW_ERROR = 7,
  ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_RAW_ERROR = 8,
  ANOMALY_MODEL_OUTPUT_SHORT_ERROR = 9,
  ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_ERROR = 10,
  UNRECOGNIZED = -1,
}

export function anomalyModelOutputFromJSON(object: any): AnomalyModelOutput {
  switch (object) {
    case 0:
    case 'ANOMALY_MODEL_OUTPUT_UNSPECIFIED':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_UNSPECIFIED
    case 1:
    case 'ANOMALY_MODEL_OUTPUT_TARGET':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_TARGET
    case 2:
    case 'ANOMALY_MODEL_OUTPUT_PREDICTION':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_PREDICTION
    case 3:
    case 'ANOMALY_MODEL_OUTPUT_ABSOLUTE_RAW_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_ABSOLUTE_RAW_ERROR
    case 4:
    case 'ANOMALY_MODEL_OUTPUT_SIGNED_RAW_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SIGNED_RAW_ERROR
    case 5:
    case 'ANOMALY_MODEL_OUTPUT_ABSOLUTE_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_ABSOLUTE_ERROR
    case 6:
    case 'ANOMALY_MODEL_OUTPUT_SIGNED_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SIGNED_ERROR
    case 7:
    case 'ANOMALY_MODEL_OUTPUT_SHORT_RAW_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_RAW_ERROR
    case 8:
    case 'ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_RAW_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_RAW_ERROR
    case 9:
    case 'ANOMALY_MODEL_OUTPUT_SHORT_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_ERROR
    case 10:
    case 'ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_ERROR':
      return AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_ERROR
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AnomalyModelOutput.UNRECOGNIZED
  }
}

export function anomalyModelOutputToJSON(object: AnomalyModelOutput): string {
  switch (object) {
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_UNSPECIFIED:
      return 'ANOMALY_MODEL_OUTPUT_UNSPECIFIED'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_TARGET:
      return 'ANOMALY_MODEL_OUTPUT_TARGET'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_PREDICTION:
      return 'ANOMALY_MODEL_OUTPUT_PREDICTION'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_ABSOLUTE_RAW_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_ABSOLUTE_RAW_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SIGNED_RAW_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SIGNED_RAW_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_ABSOLUTE_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_ABSOLUTE_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SIGNED_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SIGNED_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_RAW_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SHORT_RAW_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_RAW_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_RAW_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SHORT_ERROR'
    case AnomalyModelOutput.ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_ERROR:
      return 'ANOMALY_MODEL_OUTPUT_SHORT_SIGNED_ERROR'
    case AnomalyModelOutput.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED'
  }
}

export enum ForecastModelSignalType {
  FORECAST_MODEL_SIGNAL_TYPE_UNSPECIFIED = 0,
  FORECAST_MODEL_SIGNAL_TYPE_PREDICTION = 1,
  FORECAST_MODEL_SIGNAL_TYPE_LOWER_CONFIDENCE_BOUND = 2,
  FORECAST_MODEL_SIGNAL_TYPE_UPPER_CONFIDENCE_BOUND = 3,
  UNRECOGNIZED = -1,
}

export function forecastModelSignalTypeFromJSON(
  object: any,
): ForecastModelSignalType {
  switch (object) {
    case 0:
    case 'FORECAST_MODEL_SIGNAL_TYPE_UNSPECIFIED':
      return ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_UNSPECIFIED
    case 1:
    case 'FORECAST_MODEL_SIGNAL_TYPE_PREDICTION':
      return ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_PREDICTION
    case 2:
    case 'FORECAST_MODEL_SIGNAL_TYPE_LOWER_CONFIDENCE_BOUND':
      return ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_LOWER_CONFIDENCE_BOUND
    case 3:
    case 'FORECAST_MODEL_SIGNAL_TYPE_UPPER_CONFIDENCE_BOUND':
      return ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_UPPER_CONFIDENCE_BOUND
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ForecastModelSignalType.UNRECOGNIZED
  }
}

export function forecastModelSignalTypeToJSON(
  object: ForecastModelSignalType,
): string {
  switch (object) {
    case ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_UNSPECIFIED:
      return 'FORECAST_MODEL_SIGNAL_TYPE_UNSPECIFIED'
    case ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_PREDICTION:
      return 'FORECAST_MODEL_SIGNAL_TYPE_PREDICTION'
    case ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_LOWER_CONFIDENCE_BOUND:
      return 'FORECAST_MODEL_SIGNAL_TYPE_LOWER_CONFIDENCE_BOUND'
    case ForecastModelSignalType.FORECAST_MODEL_SIGNAL_TYPE_UPPER_CONFIDENCE_BOUND:
      return 'FORECAST_MODEL_SIGNAL_TYPE_UPPER_CONFIDENCE_BOUND'
    case ForecastModelSignalType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED'
  }
}

export enum SimType {
  SIM_TYPE_UNSPECIFIED = 0,
  SIM_TYPE_SIN = 1,
  SIM_TYPE_COS = 2,
  SIM_TYPE_RND = 3,
  SIM_TYPE_SAW = 4,
  UNRECOGNIZED = -1,
}

export function simTypeFromJSON(object: any): SimType {
  switch (object) {
    case 0:
    case 'SIM_TYPE_UNSPECIFIED':
      return SimType.SIM_TYPE_UNSPECIFIED
    case 1:
    case 'SIM_TYPE_SIN':
      return SimType.SIM_TYPE_SIN
    case 2:
    case 'SIM_TYPE_COS':
      return SimType.SIM_TYPE_COS
    case 3:
    case 'SIM_TYPE_RND':
      return SimType.SIM_TYPE_RND
    case 4:
    case 'SIM_TYPE_SAW':
      return SimType.SIM_TYPE_SAW
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SimType.UNRECOGNIZED
  }
}

export function simTypeToJSON(object: SimType): string {
  switch (object) {
    case SimType.SIM_TYPE_UNSPECIFIED:
      return 'SIM_TYPE_UNSPECIFIED'
    case SimType.SIM_TYPE_SIN:
      return 'SIM_TYPE_SIN'
    case SimType.SIM_TYPE_COS:
      return 'SIM_TYPE_COS'
    case SimType.SIM_TYPE_RND:
      return 'SIM_TYPE_RND'
    case SimType.SIM_TYPE_SAW:
      return 'SIM_TYPE_SAW'
    case SimType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED'
  }
}

export interface TimeSeries {
  kind?:
    | { $case: 'tsRef'; value: string }
    | { $case: 'tsDef'; value: TimeSeriesDef }
    | undefined
}

export interface SavedTimeSeries {
  /** For storing TimeSeriesDefs in a workspace. Couples the def with an id and metadata. */
  id: string
  meta: Meta | undefined
  tsDef: TimeSeriesDef | undefined
}

export interface TimeSeriesDef {
  /** A recipe for time series data, tied to a label */
  label: string
  /** repeated TimeSeriesAugmentation augmentations = 2; */
  description: string
  kind?:
    | { $case: 'tagId'; value: TagId }
    | { $case: 'modelOutputId'; value: ModelOutputId }
    | { $case: 'externalTimeSeriesId'; value: ExternalTimeSeriesId }
    | { $case: 'simulation'; value: SimulatedTimeSeries }
    | { $case: 'time'; value: Time }
    | { $case: 'expression'; value: TimeSeriesExpression }
    | { $case: 'condition'; value: TimeSeriesCondition }
    | { $case: 'filtered'; value: FilteredTimeSeries }
    | undefined
}

export interface TagId {
  factoryId: string
  tagName: string
}

export interface ModelOutputId {
  /** Might not be needed? */
  factoryId: string
  modelId: string
  namespace: string
  output?:
    | { $case: 'anomalyModelOutput'; value: AnomalyModelOutput }
    | {
        $case: 'forecastModelOutput'
        value: ForecastModelOutput
      }
    | undefined
}

export interface ExternalTimeSeriesId {
  /**
   * Should capture a few generally useful external data types:
   *   - Weather data
   *   - Stock market?
   * Additional suggestions welcome...
   */
  sourceId: string
  tsId: string
}

export interface ForecastModelOutput {
  signal: ForecastModelSignalType
  offset: number
}

export interface SimulatedTimeSeries {
  type: SimType
  /** time between data points */
  interval: Duration | undefined
  /** time for a full cycle */
  period: Duration | undefined
}

export interface Time {}

export interface TimeSeriesExpression {
  dependencies: { [key: string]: TimeSeries }
  expression: string
  filter?:
    | { $case: 'filterExpression'; value: string }
    | { $case: 'filterTimeseries'; value: TimeSeries }
    | undefined
  shift: Duration | undefined
}

export interface TimeSeriesExpression_DependenciesEntry {
  key: string
  value: TimeSeries | undefined
}

export interface TimeSeriesCondition {
  condition: TimeSeriesExpression | undefined
}

export interface FilteredTimeSeries {
  tag: TimeSeries | undefined
  condition: TimeSeries | undefined
}

export interface Counter {
  /** google.protobuf.Duration grid_size = 2; */
  start: number
}

export interface PointDelta {}

export interface TimeSeriesAugmentation {
  kind?:
    | { $case: 'counter'; value: Counter }
    | { $case: 'delta'; value: PointDelta }
    | undefined
}

function createBaseTimeSeries(): TimeSeries {
  return { kind: undefined }
}

export const TimeSeries = {
  encode(
    message: TimeSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'tsRef':
        writer.uint32(10).string(message.kind.value)
        break
      case 'tsDef':
        TimeSeriesDef.encode(
          message.kind.value,
          writer.uint32(18).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = { $case: 'tsRef', value: reader.string() }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'tsDef',
            value: TimeSeriesDef.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeries {
    return {
      kind: isSet(object.tsRef)
        ? { $case: 'tsRef', value: globalThis.String(object.tsRef) }
        : isSet(object.tsDef)
        ? { $case: 'tsDef', value: TimeSeriesDef.fromJSON(object.tsDef) }
        : undefined,
    }
  },

  toJSON(message: TimeSeries): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'tsRef') {
      obj.tsRef = message.kind.value
    }
    if (message.kind?.$case === 'tsDef') {
      obj.tsDef = TimeSeriesDef.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeSeries>, I>>(base?: I): TimeSeries {
    return TimeSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeries>, I>>(
    object: I,
  ): TimeSeries {
    const message = createBaseTimeSeries()
    if (
      object.kind?.$case === 'tsRef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = { $case: 'tsRef', value: object.kind.value }
    }
    if (
      object.kind?.$case === 'tsDef' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'tsDef',
        value: TimeSeriesDef.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseSavedTimeSeries(): SavedTimeSeries {
  return { id: '', meta: undefined, tsDef: undefined }
}

export const SavedTimeSeries = {
  encode(
    message: SavedTimeSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.tsDef !== undefined) {
      TimeSeriesDef.encode(message.tsDef, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedTimeSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSavedTimeSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.tsDef = TimeSeriesDef.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SavedTimeSeries {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      tsDef: isSet(object.tsDef)
        ? TimeSeriesDef.fromJSON(object.tsDef)
        : undefined,
    }
  },

  toJSON(message: SavedTimeSeries): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.tsDef !== undefined) {
      obj.tsDef = TimeSeriesDef.toJSON(message.tsDef)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SavedTimeSeries>, I>>(
    base?: I,
  ): SavedTimeSeries {
    return SavedTimeSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SavedTimeSeries>, I>>(
    object: I,
  ): SavedTimeSeries {
    const message = createBaseSavedTimeSeries()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.tsDef =
      object.tsDef !== undefined && object.tsDef !== null
        ? TimeSeriesDef.fromPartial(object.tsDef)
        : undefined
    return message
  },
}

function createBaseTimeSeriesDef(): TimeSeriesDef {
  return { label: '', description: '', kind: undefined }
}

export const TimeSeriesDef = {
  encode(
    message: TimeSeriesDef,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description)
    }
    switch (message.kind?.$case) {
      case 'tagId':
        TagId.encode(message.kind.value, writer.uint32(82).fork()).ldelim()
        break
      case 'modelOutputId':
        ModelOutputId.encode(
          message.kind.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
      case 'externalTimeSeriesId':
        ExternalTimeSeriesId.encode(
          message.kind.value,
          writer.uint32(98).fork(),
        ).ldelim()
        break
      case 'simulation':
        SimulatedTimeSeries.encode(
          message.kind.value,
          writer.uint32(106).fork(),
        ).ldelim()
        break
      case 'time':
        Time.encode(message.kind.value, writer.uint32(138).fork()).ldelim()
        break
      case 'expression':
        TimeSeriesExpression.encode(
          message.kind.value,
          writer.uint32(114).fork(),
        ).ldelim()
        break
      case 'condition':
        TimeSeriesCondition.encode(
          message.kind.value,
          writer.uint32(122).fork(),
        ).ldelim()
        break
      case 'filtered':
        FilteredTimeSeries.encode(
          message.kind.value,
          writer.uint32(130).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeSeriesDef {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeriesDef()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.label = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.description = reader.string()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.kind = {
            $case: 'tagId',
            value: TagId.decode(reader, reader.uint32()),
          }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.kind = {
            $case: 'modelOutputId',
            value: ModelOutputId.decode(reader, reader.uint32()),
          }
          continue
        case 12:
          if (tag !== 98) {
            break
          }

          message.kind = {
            $case: 'externalTimeSeriesId',
            value: ExternalTimeSeriesId.decode(reader, reader.uint32()),
          }
          continue
        case 13:
          if (tag !== 106) {
            break
          }

          message.kind = {
            $case: 'simulation',
            value: SimulatedTimeSeries.decode(reader, reader.uint32()),
          }
          continue
        case 17:
          if (tag !== 138) {
            break
          }

          message.kind = {
            $case: 'time',
            value: Time.decode(reader, reader.uint32()),
          }
          continue
        case 14:
          if (tag !== 114) {
            break
          }

          message.kind = {
            $case: 'expression',
            value: TimeSeriesExpression.decode(reader, reader.uint32()),
          }
          continue
        case 15:
          if (tag !== 122) {
            break
          }

          message.kind = {
            $case: 'condition',
            value: TimeSeriesCondition.decode(reader, reader.uint32()),
          }
          continue
        case 16:
          if (tag !== 130) {
            break
          }

          message.kind = {
            $case: 'filtered',
            value: FilteredTimeSeries.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeriesDef {
    return {
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      kind: isSet(object.tagId)
        ? { $case: 'tagId', value: TagId.fromJSON(object.tagId) }
        : isSet(object.modelOutputId)
        ? {
            $case: 'modelOutputId',
            value: ModelOutputId.fromJSON(object.modelOutputId),
          }
        : isSet(object.externalTimeSeriesId)
        ? {
            $case: 'externalTimeSeriesId',
            value: ExternalTimeSeriesId.fromJSON(object.externalTimeSeriesId),
          }
        : isSet(object.simulation)
        ? {
            $case: 'simulation',
            value: SimulatedTimeSeries.fromJSON(object.simulation),
          }
        : isSet(object.time)
        ? { $case: 'time', value: Time.fromJSON(object.time) }
        : isSet(object.expression)
        ? {
            $case: 'expression',
            value: TimeSeriesExpression.fromJSON(object.expression),
          }
        : isSet(object.condition)
        ? {
            $case: 'condition',
            value: TimeSeriesCondition.fromJSON(object.condition),
          }
        : isSet(object.filtered)
        ? {
            $case: 'filtered',
            value: FilteredTimeSeries.fromJSON(object.filtered),
          }
        : undefined,
    }
  },

  toJSON(message: TimeSeriesDef): unknown {
    const obj: any = {}
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.kind?.$case === 'tagId') {
      obj.tagId = TagId.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'modelOutputId') {
      obj.modelOutputId = ModelOutputId.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'externalTimeSeriesId') {
      obj.externalTimeSeriesId = ExternalTimeSeriesId.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'simulation') {
      obj.simulation = SimulatedTimeSeries.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'time') {
      obj.time = Time.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'expression') {
      obj.expression = TimeSeriesExpression.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'condition') {
      obj.condition = TimeSeriesCondition.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'filtered') {
      obj.filtered = FilteredTimeSeries.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeSeriesDef>, I>>(
    base?: I,
  ): TimeSeriesDef {
    return TimeSeriesDef.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesDef>, I>>(
    object: I,
  ): TimeSeriesDef {
    const message = createBaseTimeSeriesDef()
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    if (
      object.kind?.$case === 'tagId' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'tagId',
        value: TagId.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'modelOutputId' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'modelOutputId',
        value: ModelOutputId.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'externalTimeSeriesId' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'externalTimeSeriesId',
        value: ExternalTimeSeriesId.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'simulation' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'simulation',
        value: SimulatedTimeSeries.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'time' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'time',
        value: Time.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'expression' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'expression',
        value: TimeSeriesExpression.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'condition' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'condition',
        value: TimeSeriesCondition.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'filtered' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'filtered',
        value: FilteredTimeSeries.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

function createBaseTagId(): TagId {
  return { factoryId: '', tagName: '' }
}

export const TagId = {
  encode(message: TagId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.factoryId !== '') {
      writer.uint32(10).string(message.factoryId)
    }
    if (message.tagName !== '') {
      writer.uint32(18).string(message.tagName)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TagId {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTagId()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.factoryId = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.tagName = reader.string()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TagId {
    return {
      factoryId: isSet(object.factoryId)
        ? globalThis.String(object.factoryId)
        : '',
      tagName: isSet(object.tagName) ? globalThis.String(object.tagName) : '',
    }
  },

  toJSON(message: TagId): unknown {
    const obj: any = {}
    if (message.factoryId !== '') {
      obj.factoryId = message.factoryId
    }
    if (message.tagName !== '') {
      obj.tagName = message.tagName
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TagId>, I>>(base?: I): TagId {
    return TagId.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TagId>, I>>(object: I): TagId {
    const message = createBaseTagId()
    message.factoryId = object.factoryId ?? ''
    message.tagName = object.tagName ?? ''
    return message
  },
}

function createBaseModelOutputId(): ModelOutputId {
  return { factoryId: '', modelId: '', namespace: '', output: undefined }
}

export const ModelOutputId = {
  encode(
    message: ModelOutputId,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.factoryId !== '') {
      writer.uint32(10).string(message.factoryId)
    }
    if (message.modelId !== '') {
      writer.uint32(18).string(message.modelId)
    }
    if (message.namespace !== '') {
      writer.uint32(26).string(message.namespace)
    }
    switch (message.output?.$case) {
      case 'anomalyModelOutput':
        writer.uint32(80).int32(message.output.value)
        break
      case 'forecastModelOutput':
        ForecastModelOutput.encode(
          message.output.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModelOutputId {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseModelOutputId()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.factoryId = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.modelId = reader.string()
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.namespace = reader.string()
          continue
        case 10:
          if (tag !== 80) {
            break
          }

          message.output = {
            $case: 'anomalyModelOutput',
            value: reader.int32() as any,
          }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.output = {
            $case: 'forecastModelOutput',
            value: ForecastModelOutput.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ModelOutputId {
    return {
      factoryId: isSet(object.factoryId)
        ? globalThis.String(object.factoryId)
        : '',
      modelId: isSet(object.modelId) ? globalThis.String(object.modelId) : '',
      namespace: isSet(object.namespace)
        ? globalThis.String(object.namespace)
        : '',
      output: isSet(object.anomalyModelOutput)
        ? {
            $case: 'anomalyModelOutput',
            value: anomalyModelOutputFromJSON(object.anomalyModelOutput),
          }
        : isSet(object.forecastModelOutput)
        ? {
            $case: 'forecastModelOutput',
            value: ForecastModelOutput.fromJSON(object.forecastModelOutput),
          }
        : undefined,
    }
  },

  toJSON(message: ModelOutputId): unknown {
    const obj: any = {}
    if (message.factoryId !== '') {
      obj.factoryId = message.factoryId
    }
    if (message.modelId !== '') {
      obj.modelId = message.modelId
    }
    if (message.namespace !== '') {
      obj.namespace = message.namespace
    }
    if (message.output?.$case === 'anomalyModelOutput') {
      obj.anomalyModelOutput = anomalyModelOutputToJSON(message.output.value)
    }
    if (message.output?.$case === 'forecastModelOutput') {
      obj.forecastModelOutput = ForecastModelOutput.toJSON(message.output.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ModelOutputId>, I>>(
    base?: I,
  ): ModelOutputId {
    return ModelOutputId.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ModelOutputId>, I>>(
    object: I,
  ): ModelOutputId {
    const message = createBaseModelOutputId()
    message.factoryId = object.factoryId ?? ''
    message.modelId = object.modelId ?? ''
    message.namespace = object.namespace ?? ''
    if (
      object.output?.$case === 'anomalyModelOutput' &&
      object.output?.value !== undefined &&
      object.output?.value !== null
    ) {
      message.output = {
        $case: 'anomalyModelOutput',
        value: object.output.value,
      }
    }
    if (
      object.output?.$case === 'forecastModelOutput' &&
      object.output?.value !== undefined &&
      object.output?.value !== null
    ) {
      message.output = {
        $case: 'forecastModelOutput',
        value: ForecastModelOutput.fromPartial(object.output.value),
      }
    }
    return message
  },
}

function createBaseExternalTimeSeriesId(): ExternalTimeSeriesId {
  return { sourceId: '', tsId: '' }
}

export const ExternalTimeSeriesId = {
  encode(
    message: ExternalTimeSeriesId,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sourceId !== '') {
      writer.uint32(10).string(message.sourceId)
    }
    if (message.tsId !== '') {
      writer.uint32(18).string(message.tsId)
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExternalTimeSeriesId {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseExternalTimeSeriesId()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.sourceId = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.tsId = reader.string()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ExternalTimeSeriesId {
    return {
      sourceId: isSet(object.sourceId)
        ? globalThis.String(object.sourceId)
        : '',
      tsId: isSet(object.tsId) ? globalThis.String(object.tsId) : '',
    }
  },

  toJSON(message: ExternalTimeSeriesId): unknown {
    const obj: any = {}
    if (message.sourceId !== '') {
      obj.sourceId = message.sourceId
    }
    if (message.tsId !== '') {
      obj.tsId = message.tsId
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ExternalTimeSeriesId>, I>>(
    base?: I,
  ): ExternalTimeSeriesId {
    return ExternalTimeSeriesId.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ExternalTimeSeriesId>, I>>(
    object: I,
  ): ExternalTimeSeriesId {
    const message = createBaseExternalTimeSeriesId()
    message.sourceId = object.sourceId ?? ''
    message.tsId = object.tsId ?? ''
    return message
  },
}

function createBaseForecastModelOutput(): ForecastModelOutput {
  return { signal: 0, offset: 0 }
}

export const ForecastModelOutput = {
  encode(
    message: ForecastModelOutput,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.signal !== 0) {
      writer.uint32(8).int32(message.signal)
    }
    if (message.offset !== 0) {
      writer.uint32(16).uint32(message.offset)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ForecastModelOutput {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseForecastModelOutput()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.signal = reader.int32() as any
          continue
        case 2:
          if (tag !== 16) {
            break
          }

          message.offset = reader.uint32()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ForecastModelOutput {
    return {
      signal: isSet(object.signal)
        ? forecastModelSignalTypeFromJSON(object.signal)
        : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
    }
  },

  toJSON(message: ForecastModelOutput): unknown {
    const obj: any = {}
    if (message.signal !== 0) {
      obj.signal = forecastModelSignalTypeToJSON(message.signal)
    }
    if (message.offset !== 0) {
      obj.offset = Math.round(message.offset)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ForecastModelOutput>, I>>(
    base?: I,
  ): ForecastModelOutput {
    return ForecastModelOutput.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ForecastModelOutput>, I>>(
    object: I,
  ): ForecastModelOutput {
    const message = createBaseForecastModelOutput()
    message.signal = object.signal ?? 0
    message.offset = object.offset ?? 0
    return message
  },
}

function createBaseSimulatedTimeSeries(): SimulatedTimeSeries {
  return { type: 0, interval: undefined, period: undefined }
}

export const SimulatedTimeSeries = {
  encode(
    message: SimulatedTimeSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type)
    }
    if (message.interval !== undefined) {
      Duration.encode(message.interval, writer.uint32(18).fork()).ldelim()
    }
    if (message.period !== undefined) {
      Duration.encode(message.period, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimulatedTimeSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseSimulatedTimeSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.type = reader.int32() as any
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.interval = Duration.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.period = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): SimulatedTimeSeries {
    return {
      type: isSet(object.type) ? simTypeFromJSON(object.type) : 0,
      interval: isSet(object.interval)
        ? Duration.fromJSON(object.interval)
        : undefined,
      period: isSet(object.period)
        ? Duration.fromJSON(object.period)
        : undefined,
    }
  },

  toJSON(message: SimulatedTimeSeries): unknown {
    const obj: any = {}
    if (message.type !== 0) {
      obj.type = simTypeToJSON(message.type)
    }
    if (message.interval !== undefined) {
      obj.interval = Duration.toJSON(message.interval)
    }
    if (message.period !== undefined) {
      obj.period = Duration.toJSON(message.period)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<SimulatedTimeSeries>, I>>(
    base?: I,
  ): SimulatedTimeSeries {
    return SimulatedTimeSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<SimulatedTimeSeries>, I>>(
    object: I,
  ): SimulatedTimeSeries {
    const message = createBaseSimulatedTimeSeries()
    message.type = object.type ?? 0
    message.interval =
      object.interval !== undefined && object.interval !== null
        ? Duration.fromPartial(object.interval)
        : undefined
    message.period =
      object.period !== undefined && object.period !== null
        ? Duration.fromPartial(object.period)
        : undefined
    return message
  },
}

function createBaseTime(): Time {
  return {}
}

export const Time = {
  encode(_: Time, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Time {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTime()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(_: any): Time {
    return {}
  },

  toJSON(_: Time): unknown {
    const obj: any = {}
    return obj
  },

  create<I extends Exact<DeepPartial<Time>, I>>(base?: I): Time {
    return Time.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Time>, I>>(_: I): Time {
    const message = createBaseTime()
    return message
  },
}

function createBaseTimeSeriesExpression(): TimeSeriesExpression {
  return {
    dependencies: {},
    expression: '',
    filter: undefined,
    shift: undefined,
  }
}

export const TimeSeriesExpression = {
  encode(
    message: TimeSeriesExpression,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.dependencies).forEach(([key, value]) => {
      TimeSeriesExpression_DependenciesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim()
    })
    if (message.expression !== '') {
      writer.uint32(18).string(message.expression)
    }
    switch (message.filter?.$case) {
      case 'filterExpression':
        writer.uint32(82).string(message.filter.value)
        break
      case 'filterTimeseries':
        TimeSeries.encode(
          message.filter.value,
          writer.uint32(90).fork(),
        ).ldelim()
        break
    }
    if (message.shift !== undefined) {
      Duration.encode(message.shift, writer.uint32(98).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TimeSeriesExpression {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeriesExpression()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          const entry1 = TimeSeriesExpression_DependenciesEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry1.value !== undefined) {
            message.dependencies[entry1.key] = entry1.value
          }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.expression = reader.string()
          continue
        case 10:
          if (tag !== 82) {
            break
          }

          message.filter = { $case: 'filterExpression', value: reader.string() }
          continue
        case 11:
          if (tag !== 90) {
            break
          }

          message.filter = {
            $case: 'filterTimeseries',
            value: TimeSeries.decode(reader, reader.uint32()),
          }
          continue
        case 12:
          if (tag !== 98) {
            break
          }

          message.shift = Duration.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeriesExpression {
    return {
      dependencies: isObject(object.dependencies)
        ? Object.entries(object.dependencies).reduce<{
            [key: string]: TimeSeries
          }>((acc, [key, value]) => {
            acc[key] = TimeSeries.fromJSON(value)
            return acc
          }, {})
        : {},
      expression: isSet(object.expression)
        ? globalThis.String(object.expression)
        : '',
      filter: isSet(object.filterExpression)
        ? {
            $case: 'filterExpression',
            value: globalThis.String(object.filterExpression),
          }
        : isSet(object.filterTimeseries)
        ? {
            $case: 'filterTimeseries',
            value: TimeSeries.fromJSON(object.filterTimeseries),
          }
        : undefined,
      shift: isSet(object.shift) ? Duration.fromJSON(object.shift) : undefined,
    }
  },

  toJSON(message: TimeSeriesExpression): unknown {
    const obj: any = {}
    if (message.dependencies) {
      const entries = Object.entries(message.dependencies)
      if (entries.length > 0) {
        obj.dependencies = {}
        entries.forEach(([k, v]) => {
          obj.dependencies[k] = TimeSeries.toJSON(v)
        })
      }
    }
    if (message.expression !== '') {
      obj.expression = message.expression
    }
    if (message.filter?.$case === 'filterExpression') {
      obj.filterExpression = message.filter.value
    }
    if (message.filter?.$case === 'filterTimeseries') {
      obj.filterTimeseries = TimeSeries.toJSON(message.filter.value)
    }
    if (message.shift !== undefined) {
      obj.shift = Duration.toJSON(message.shift)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeSeriesExpression>, I>>(
    base?: I,
  ): TimeSeriesExpression {
    return TimeSeriesExpression.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesExpression>, I>>(
    object: I,
  ): TimeSeriesExpression {
    const message = createBaseTimeSeriesExpression()
    message.dependencies = Object.entries(object.dependencies ?? {}).reduce<{
      [key: string]: TimeSeries
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = TimeSeries.fromPartial(value)
      }
      return acc
    }, {})
    message.expression = object.expression ?? ''
    if (
      object.filter?.$case === 'filterExpression' &&
      object.filter?.value !== undefined &&
      object.filter?.value !== null
    ) {
      message.filter = { $case: 'filterExpression', value: object.filter.value }
    }
    if (
      object.filter?.$case === 'filterTimeseries' &&
      object.filter?.value !== undefined &&
      object.filter?.value !== null
    ) {
      message.filter = {
        $case: 'filterTimeseries',
        value: TimeSeries.fromPartial(object.filter.value),
      }
    }
    message.shift =
      object.shift !== undefined && object.shift !== null
        ? Duration.fromPartial(object.shift)
        : undefined
    return message
  },
}

function createBaseTimeSeriesExpression_DependenciesEntry(): TimeSeriesExpression_DependenciesEntry {
  return { key: '', value: undefined }
}

export const TimeSeriesExpression_DependenciesEntry = {
  encode(
    message: TimeSeriesExpression_DependenciesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      TimeSeries.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TimeSeriesExpression_DependenciesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeriesExpression_DependenciesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = TimeSeries.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeriesExpression_DependenciesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? TimeSeries.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: TimeSeriesExpression_DependenciesEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = TimeSeries.toJSON(message.value)
    }
    return obj
  },

  create<
    I extends Exact<DeepPartial<TimeSeriesExpression_DependenciesEntry>, I>,
  >(base?: I): TimeSeriesExpression_DependenciesEntry {
    return TimeSeriesExpression_DependenciesEntry.fromPartial(
      base ?? ({} as any),
    )
  },
  fromPartial<
    I extends Exact<DeepPartial<TimeSeriesExpression_DependenciesEntry>, I>,
  >(object: I): TimeSeriesExpression_DependenciesEntry {
    const message = createBaseTimeSeriesExpression_DependenciesEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? TimeSeries.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBaseTimeSeriesCondition(): TimeSeriesCondition {
  return { condition: undefined }
}

export const TimeSeriesCondition = {
  encode(
    message: TimeSeriesCondition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.condition !== undefined) {
      TimeSeriesExpression.encode(
        message.condition,
        writer.uint32(10).fork(),
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeSeriesCondition {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeriesCondition()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.condition = TimeSeriesExpression.decode(
            reader,
            reader.uint32(),
          )
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeriesCondition {
    return {
      condition: isSet(object.condition)
        ? TimeSeriesExpression.fromJSON(object.condition)
        : undefined,
    }
  },

  toJSON(message: TimeSeriesCondition): unknown {
    const obj: any = {}
    if (message.condition !== undefined) {
      obj.condition = TimeSeriesExpression.toJSON(message.condition)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeSeriesCondition>, I>>(
    base?: I,
  ): TimeSeriesCondition {
    return TimeSeriesCondition.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesCondition>, I>>(
    object: I,
  ): TimeSeriesCondition {
    const message = createBaseTimeSeriesCondition()
    message.condition =
      object.condition !== undefined && object.condition !== null
        ? TimeSeriesExpression.fromPartial(object.condition)
        : undefined
    return message
  },
}

function createBaseFilteredTimeSeries(): FilteredTimeSeries {
  return { tag: undefined, condition: undefined }
}

export const FilteredTimeSeries = {
  encode(
    message: FilteredTimeSeries,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.tag !== undefined) {
      TimeSeries.encode(message.tag, writer.uint32(10).fork()).ldelim()
    }
    if (message.condition !== undefined) {
      TimeSeries.encode(message.condition, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilteredTimeSeries {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseFilteredTimeSeries()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.tag = TimeSeries.decode(reader, reader.uint32())
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.condition = TimeSeries.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): FilteredTimeSeries {
    return {
      tag: isSet(object.tag) ? TimeSeries.fromJSON(object.tag) : undefined,
      condition: isSet(object.condition)
        ? TimeSeries.fromJSON(object.condition)
        : undefined,
    }
  },

  toJSON(message: FilteredTimeSeries): unknown {
    const obj: any = {}
    if (message.tag !== undefined) {
      obj.tag = TimeSeries.toJSON(message.tag)
    }
    if (message.condition !== undefined) {
      obj.condition = TimeSeries.toJSON(message.condition)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<FilteredTimeSeries>, I>>(
    base?: I,
  ): FilteredTimeSeries {
    return FilteredTimeSeries.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<FilteredTimeSeries>, I>>(
    object: I,
  ): FilteredTimeSeries {
    const message = createBaseFilteredTimeSeries()
    message.tag =
      object.tag !== undefined && object.tag !== null
        ? TimeSeries.fromPartial(object.tag)
        : undefined
    message.condition =
      object.condition !== undefined && object.condition !== null
        ? TimeSeries.fromPartial(object.condition)
        : undefined
    return message
  },
}

function createBaseCounter(): Counter {
  return { start: 0 }
}

export const Counter = {
  encode(
    message: Counter,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.start !== 0) {
      writer.uint32(8).int32(message.start)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Counter {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseCounter()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break
          }

          message.start = reader.int32()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): Counter {
    return { start: isSet(object.start) ? globalThis.Number(object.start) : 0 }
  },

  toJSON(message: Counter): unknown {
    const obj: any = {}
    if (message.start !== 0) {
      obj.start = Math.round(message.start)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<Counter>, I>>(base?: I): Counter {
    return Counter.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<Counter>, I>>(object: I): Counter {
    const message = createBaseCounter()
    message.start = object.start ?? 0
    return message
  },
}

function createBasePointDelta(): PointDelta {
  return {}
}

export const PointDelta = {
  encode(_: PointDelta, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PointDelta {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBasePointDelta()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(_: any): PointDelta {
    return {}
  },

  toJSON(_: PointDelta): unknown {
    const obj: any = {}
    return obj
  },

  create<I extends Exact<DeepPartial<PointDelta>, I>>(base?: I): PointDelta {
    return PointDelta.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<PointDelta>, I>>(_: I): PointDelta {
    const message = createBasePointDelta()
    return message
  },
}

function createBaseTimeSeriesAugmentation(): TimeSeriesAugmentation {
  return { kind: undefined }
}

export const TimeSeriesAugmentation = {
  encode(
    message: TimeSeriesAugmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    switch (message.kind?.$case) {
      case 'counter':
        Counter.encode(message.kind.value, writer.uint32(10).fork()).ldelim()
        break
      case 'delta':
        PointDelta.encode(message.kind.value, writer.uint32(18).fork()).ldelim()
        break
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TimeSeriesAugmentation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseTimeSeriesAugmentation()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.kind = {
            $case: 'counter',
            value: Counter.decode(reader, reader.uint32()),
          }
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.kind = {
            $case: 'delta',
            value: PointDelta.decode(reader, reader.uint32()),
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): TimeSeriesAugmentation {
    return {
      kind: isSet(object.counter)
        ? { $case: 'counter', value: Counter.fromJSON(object.counter) }
        : isSet(object.delta)
        ? { $case: 'delta', value: PointDelta.fromJSON(object.delta) }
        : undefined,
    }
  },

  toJSON(message: TimeSeriesAugmentation): unknown {
    const obj: any = {}
    if (message.kind?.$case === 'counter') {
      obj.counter = Counter.toJSON(message.kind.value)
    }
    if (message.kind?.$case === 'delta') {
      obj.delta = PointDelta.toJSON(message.kind.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<TimeSeriesAugmentation>, I>>(
    base?: I,
  ): TimeSeriesAugmentation {
    return TimeSeriesAugmentation.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<TimeSeriesAugmentation>, I>>(
    object: I,
  ): TimeSeriesAugmentation {
    const message = createBaseTimeSeriesAugmentation()
    if (
      object.kind?.$case === 'counter' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'counter',
        value: Counter.fromPartial(object.kind.value),
      }
    }
    if (
      object.kind?.$case === 'delta' &&
      object.kind?.value !== undefined &&
      object.kind?.value !== null
    ) {
      message.kind = {
        $case: 'delta',
        value: PointDelta.fromPartial(object.kind.value),
      }
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
