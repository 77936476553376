import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import DraggableGrid, { DraggableItem } from 'ruuri'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { debounce, isEqual, throttle } from 'lodash'
import { ChartOptions, ChartSize, ChartSizes } from 'src/types/chartTypes'
import { triggerChartRedraw } from 'src/utility'
import { Chart } from '../Chart'

interface TrendChartListProps {
  charts: ChartOptions[]
  setChartOrder: (newOrder: number[]) => void
  selectedSize: ChartSize
  isFullWidth: boolean
}

function Layout({
  charts,
  setChartOrder,
  selectedSize,
  isFullWidth,
}: TrendChartListProps): JSX.Element {
  const [boxSize, setBoxSize] = useState<number>(ChartSizes[selectedSize].size)
  const [boxHeight, setBoxHeight] = useState<number>(
    ChartSizes[selectedSize].size / 1.7,
  )
  const ref = useRef<HTMLDivElement>(null)
  const heightContainerRef = useRef<HTMLDivElement>(null)

  const { trendRenderLimit } = useFlags()

  const handleBoxSize = useCallback(
    (size: ChartSize): void => {
      if (charts.length === 0 || !ref.current) return
      const { clientWidth } = ref.current!
      const { offsetHeight, clientHeight } = heightContainerRef.current!

      let numberOfColumns = Math.floor(clientWidth / ChartSizes[size].size)
      if (charts.length < numberOfColumns) {
        numberOfColumns = charts.length
      }
      let widthOfItem = clientWidth / numberOfColumns

      if (widthOfItem > ChartSizes[size].maxSize) {
        widthOfItem = ChartSizes[size].maxSize
      }

      if (numberOfColumns === 1) {
        widthOfItem = clientWidth
      }

      if (widthOfItem !== boxSize) {
        // Prevent infinite re-renders if the flag is enabled
        if (trendRenderLimit) throttle(setBoxSize, 200)(widthOfItem)
        else setBoxSize(widthOfItem)
      }

      const numberOfRows = Math.ceil(charts.length / numberOfColumns)
      const heightOfItem =
        numberOfRows === 1 ? clientHeight : offsetHeight / numberOfRows
      if (
        heightOfItem < ChartSizes[size].maxSize / 1.7 &&
        heightOfItem > ChartSizes[size].size / 1.7
      ) {
        setBoxHeight(heightOfItem)
      } else {
        setBoxHeight(Math.min(widthOfItem / 1.7, offsetHeight - 1))
      }
    },
    [boxSize, charts.length, trendRenderLimit],
  )

  useEffect(() => {
    const handleChartDebounced = debounce(triggerChartRedraw, 200)
    handleChartDebounced()
  }, [boxSize, boxHeight, isFullWidth])

  useLayoutEffect(() => {
    const debouncedHandleSize = debounce(() => handleBoxSize(selectedSize), 200)
    window.addEventListener('resize', debouncedHandleSize)
    handleBoxSize(selectedSize)
    return () => {
      window.removeEventListener('resize', debouncedHandleSize)
    }
  }, [selectedSize, handleBoxSize])

  const overflowSetting = charts.length > 1 ? 'overflow-hidden' : ''

  const containerWidth = ref.current?.clientWidth

  return (
    <div className="size-full max-w-full px-xs" ref={heightContainerRef}>
      <div
        className={`size-full max-h-full overflow-y-auto ${overflowSetting}`}
        ref={ref}
      >
        <DraggableGrid
          dragHandle={'.drag-handle'}
          dragEnabled={true}
          layoutDuration={1000}
          onLayoutEnd={layout => {
            const newOrder = layout.map(item => {
              const id = item.getElement()?.children[0]?.id
              // if the id is missing (-1) or unparseable (NaN) it should be an error
              return id ? parseInt(id, 10) : -1
            })
            setChartOrder(newOrder)
          }}
        >
          {charts.map((chart, index) => (
            <DraggableItem
              itemID={chart.id.toString()}
              id={chart.id.toString()}
              key={chart.id}
              className="!cursor-default"
            >
              <div
                className="p-2"
                style={{
                  width: isFullWidth
                    ? containerWidth
                    : boxSize < containerWidth!
                    ? boxSize
                    : containerWidth,
                  maxWidth: containerWidth,
                  height: boxHeight,
                }}
              >
                <Chart key={chart.id} index={index} />
              </div>
            </DraggableItem>
          ))}
        </DraggableGrid>
      </div>
    </div>
  )
}

function shouldLayoutRerender(
  prevProps: TrendChartListProps,
  nextProps: TrendChartListProps,
): boolean {
  if (prevProps.selectedSize !== nextProps.selectedSize) return false
  if (prevProps.isFullWidth !== nextProps.isFullWidth) return false
  if (prevProps.charts.length !== nextProps.charts.length) return false

  // Only re-render if the order has changed
  return (
    prevProps.charts.map(ch => ch.id).toString() ===
    nextProps.charts.map(ch => ch.id).toString()
  )
}

export const TrendLayout = memo(Layout, shouldLayoutRerender)
