import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { RoutingTabs, Spinner } from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { useGateway } from '../api'
import { GatewayOverview } from './gateway-details'
import { GatewayDetailsNavigation } from './GatewayDetailsNavigation'

const tabs = [
  {
    title: 'OPC-UA Connections',
    path: '',
  },
]
export function GatewayDetailsPage(): JSX.Element {
  const { orgId, siteId, gatewayId } = useParams()
  if (!orgId || !siteId || !gatewayId)
    throw new Error('Missing orgId, siteId or gatewayId')
  const { setTitleComponent } = useNavigationContext()

  const { data: gateway, isError, isLoading } = useGateway(siteId, gatewayId)
  const navigate = useNavigate()

  useEffect(() => {
    setTitleComponent(
      <GatewayDetailsNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
      />,
    )
    return () => setTitleComponent(null)
  }, [gatewayId, orgId, setTitleComponent, siteId])

  if (isError) {
    toast.error('Gateway not found')
    navigate('/settings/orgs')
    return <></>
  }

  return (
    <div className="flex h-full flex-col p-s">
      <div className="flex flex-1 flex-col gap-m rounded-2xs bg-white px-s py-m">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <GatewayOverview gateway={gateway} />
            {/* TODO: We currently don't have any data from gateway */}
            {/* <DataOverview /> */}
            <div className="flex flex-1 flex-col gap-m">
              <RoutingTabs tabs={tabs} />
              <Outlet />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
