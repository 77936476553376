// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: workspace.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Analysis } from './analysis'
import { Meta } from './common'
import { ExportedAnalysis } from './public_export'
import { SavedSegmentation } from './segmentation'
import { SavedTimeRange } from './time_range'
import { SavedTimeSeries } from './time_series'

export const protobufPackage = 'types.v1'

export interface WorkspaceState {
  /** version of workspace (semver?) */
  version: string
  /** unique id for workspace */
  id: string
  meta: Meta | undefined
  /** is this email? or is it the underlying db id for the user? */
  ownerId: string
  /** name shown in the UI. does not need to be unique (should it be?) */
  label: string
  description: string
  /** should this workspace be visible to other users in the same site */
  shared: boolean
  timeSeries: { [key: string]: SavedTimeSeries }
  timeRanges: { [key: string]: SavedTimeRange }
  analyses: { [key: string]: Analysis }
  segmentations: { [key: string]: SavedSegmentation }
  exports: { [key: string]: ExportedAnalysis }
}

export interface WorkspaceState_TimeSeriesEntry {
  key: string
  value: SavedTimeSeries | undefined
}

export interface WorkspaceState_TimeRangesEntry {
  key: string
  value: SavedTimeRange | undefined
}

export interface WorkspaceState_AnalysesEntry {
  key: string
  value: Analysis | undefined
}

export interface WorkspaceState_SegmentationsEntry {
  key: string
  value: SavedSegmentation | undefined
}

export interface WorkspaceState_ExportsEntry {
  key: string
  value: ExportedAnalysis | undefined
}

function createBaseWorkspaceState(): WorkspaceState {
  return {
    version: '',
    id: '',
    meta: undefined,
    ownerId: '',
    label: '',
    description: '',
    shared: false,
    timeSeries: {},
    timeRanges: {},
    analyses: {},
    segmentations: {},
    exports: {},
  }
}

export const WorkspaceState = {
  encode(
    message: WorkspaceState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.version !== '') {
      writer.uint32(10).string(message.version)
    }
    if (message.id !== '') {
      writer.uint32(18).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(26).fork()).ldelim()
    }
    if (message.ownerId !== '') {
      writer.uint32(34).string(message.ownerId)
    }
    if (message.label !== '') {
      writer.uint32(42).string(message.label)
    }
    if (message.description !== '') {
      writer.uint32(50).string(message.description)
    }
    if (message.shared !== false) {
      writer.uint32(56).bool(message.shared)
    }
    Object.entries(message.timeSeries).forEach(([key, value]) => {
      WorkspaceState_TimeSeriesEntry.encode(
        { key: key as any, value },
        writer.uint32(802).fork(),
      ).ldelim()
    })
    Object.entries(message.timeRanges).forEach(([key, value]) => {
      WorkspaceState_TimeRangesEntry.encode(
        { key: key as any, value },
        writer.uint32(810).fork(),
      ).ldelim()
    })
    Object.entries(message.analyses).forEach(([key, value]) => {
      WorkspaceState_AnalysesEntry.encode(
        { key: key as any, value },
        writer.uint32(818).fork(),
      ).ldelim()
    })
    Object.entries(message.segmentations).forEach(([key, value]) => {
      WorkspaceState_SegmentationsEntry.encode(
        { key: key as any, value },
        writer.uint32(826).fork(),
      ).ldelim()
    })
    Object.entries(message.exports).forEach(([key, value]) => {
      WorkspaceState_ExportsEntry.encode(
        { key: key as any, value },
        writer.uint32(834).fork(),
      ).ldelim()
    })
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkspaceState {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.version = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.id = reader.string()
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.ownerId = reader.string()
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.label = reader.string()
          continue
        case 6:
          if (tag !== 50) {
            break
          }

          message.description = reader.string()
          continue
        case 7:
          if (tag !== 56) {
            break
          }

          message.shared = reader.bool()
          continue
        case 100:
          if (tag !== 802) {
            break
          }

          const entry100 = WorkspaceState_TimeSeriesEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry100.value !== undefined) {
            message.timeSeries[entry100.key] = entry100.value
          }
          continue
        case 101:
          if (tag !== 810) {
            break
          }

          const entry101 = WorkspaceState_TimeRangesEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry101.value !== undefined) {
            message.timeRanges[entry101.key] = entry101.value
          }
          continue
        case 102:
          if (tag !== 818) {
            break
          }

          const entry102 = WorkspaceState_AnalysesEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry102.value !== undefined) {
            message.analyses[entry102.key] = entry102.value
          }
          continue
        case 103:
          if (tag !== 826) {
            break
          }

          const entry103 = WorkspaceState_SegmentationsEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry103.value !== undefined) {
            message.segmentations[entry103.key] = entry103.value
          }
          continue
        case 104:
          if (tag !== 834) {
            break
          }

          const entry104 = WorkspaceState_ExportsEntry.decode(
            reader,
            reader.uint32(),
          )
          if (entry104.value !== undefined) {
            message.exports[entry104.key] = entry104.value
          }
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState {
    return {
      version: isSet(object.version) ? globalThis.String(object.version) : '',
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      ownerId: isSet(object.ownerId) ? globalThis.String(object.ownerId) : '',
      label: isSet(object.label) ? globalThis.String(object.label) : '',
      description: isSet(object.description)
        ? globalThis.String(object.description)
        : '',
      shared: isSet(object.shared) ? globalThis.Boolean(object.shared) : false,
      timeSeries: isObject(object.timeSeries)
        ? Object.entries(object.timeSeries).reduce<{
            [key: string]: SavedTimeSeries
          }>((acc, [key, value]) => {
            acc[key] = SavedTimeSeries.fromJSON(value)
            return acc
          }, {})
        : {},
      timeRanges: isObject(object.timeRanges)
        ? Object.entries(object.timeRanges).reduce<{
            [key: string]: SavedTimeRange
          }>((acc, [key, value]) => {
            acc[key] = SavedTimeRange.fromJSON(value)
            return acc
          }, {})
        : {},
      analyses: isObject(object.analyses)
        ? Object.entries(object.analyses).reduce<{ [key: string]: Analysis }>(
            (acc, [key, value]) => {
              acc[key] = Analysis.fromJSON(value)
              return acc
            },
            {},
          )
        : {},
      segmentations: isObject(object.segmentations)
        ? Object.entries(object.segmentations).reduce<{
            [key: string]: SavedSegmentation
          }>((acc, [key, value]) => {
            acc[key] = SavedSegmentation.fromJSON(value)
            return acc
          }, {})
        : {},
      exports: isObject(object.exports)
        ? Object.entries(object.exports).reduce<{
            [key: string]: ExportedAnalysis
          }>((acc, [key, value]) => {
            acc[key] = ExportedAnalysis.fromJSON(value)
            return acc
          }, {})
        : {},
    }
  },

  toJSON(message: WorkspaceState): unknown {
    const obj: any = {}
    if (message.version !== '') {
      obj.version = message.version
    }
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.ownerId !== '') {
      obj.ownerId = message.ownerId
    }
    if (message.label !== '') {
      obj.label = message.label
    }
    if (message.description !== '') {
      obj.description = message.description
    }
    if (message.shared !== false) {
      obj.shared = message.shared
    }
    if (message.timeSeries) {
      const entries = Object.entries(message.timeSeries)
      if (entries.length > 0) {
        obj.timeSeries = {}
        entries.forEach(([k, v]) => {
          obj.timeSeries[k] = SavedTimeSeries.toJSON(v)
        })
      }
    }
    if (message.timeRanges) {
      const entries = Object.entries(message.timeRanges)
      if (entries.length > 0) {
        obj.timeRanges = {}
        entries.forEach(([k, v]) => {
          obj.timeRanges[k] = SavedTimeRange.toJSON(v)
        })
      }
    }
    if (message.analyses) {
      const entries = Object.entries(message.analyses)
      if (entries.length > 0) {
        obj.analyses = {}
        entries.forEach(([k, v]) => {
          obj.analyses[k] = Analysis.toJSON(v)
        })
      }
    }
    if (message.segmentations) {
      const entries = Object.entries(message.segmentations)
      if (entries.length > 0) {
        obj.segmentations = {}
        entries.forEach(([k, v]) => {
          obj.segmentations[k] = SavedSegmentation.toJSON(v)
        })
      }
    }
    if (message.exports) {
      const entries = Object.entries(message.exports)
      if (entries.length > 0) {
        obj.exports = {}
        entries.forEach(([k, v]) => {
          obj.exports[k] = ExportedAnalysis.toJSON(v)
        })
      }
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState>, I>>(
    base?: I,
  ): WorkspaceState {
    return WorkspaceState.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<WorkspaceState>, I>>(
    object: I,
  ): WorkspaceState {
    const message = createBaseWorkspaceState()
    message.version = object.version ?? ''
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.ownerId = object.ownerId ?? ''
    message.label = object.label ?? ''
    message.description = object.description ?? ''
    message.shared = object.shared ?? false
    message.timeSeries = Object.entries(object.timeSeries ?? {}).reduce<{
      [key: string]: SavedTimeSeries
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SavedTimeSeries.fromPartial(value)
      }
      return acc
    }, {})
    message.timeRanges = Object.entries(object.timeRanges ?? {}).reduce<{
      [key: string]: SavedTimeRange
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SavedTimeRange.fromPartial(value)
      }
      return acc
    }, {})
    message.analyses = Object.entries(object.analyses ?? {}).reduce<{
      [key: string]: Analysis
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Analysis.fromPartial(value)
      }
      return acc
    }, {})
    message.segmentations = Object.entries(object.segmentations ?? {}).reduce<{
      [key: string]: SavedSegmentation
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SavedSegmentation.fromPartial(value)
      }
      return acc
    }, {})
    message.exports = Object.entries(object.exports ?? {}).reduce<{
      [key: string]: ExportedAnalysis
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = ExportedAnalysis.fromPartial(value)
      }
      return acc
    }, {})
    return message
  },
}

function createBaseWorkspaceState_TimeSeriesEntry(): WorkspaceState_TimeSeriesEntry {
  return { key: '', value: undefined }
}

export const WorkspaceState_TimeSeriesEntry = {
  encode(
    message: WorkspaceState_TimeSeriesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      SavedTimeSeries.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WorkspaceState_TimeSeriesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState_TimeSeriesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = SavedTimeSeries.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState_TimeSeriesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? SavedTimeSeries.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: WorkspaceState_TimeSeriesEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = SavedTimeSeries.toJSON(message.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState_TimeSeriesEntry>, I>>(
    base?: I,
  ): WorkspaceState_TimeSeriesEntry {
    return WorkspaceState_TimeSeriesEntry.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<WorkspaceState_TimeSeriesEntry>, I>>(
    object: I,
  ): WorkspaceState_TimeSeriesEntry {
    const message = createBaseWorkspaceState_TimeSeriesEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? SavedTimeSeries.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBaseWorkspaceState_TimeRangesEntry(): WorkspaceState_TimeRangesEntry {
  return { key: '', value: undefined }
}

export const WorkspaceState_TimeRangesEntry = {
  encode(
    message: WorkspaceState_TimeRangesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      SavedTimeRange.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WorkspaceState_TimeRangesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState_TimeRangesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = SavedTimeRange.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState_TimeRangesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? SavedTimeRange.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: WorkspaceState_TimeRangesEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = SavedTimeRange.toJSON(message.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState_TimeRangesEntry>, I>>(
    base?: I,
  ): WorkspaceState_TimeRangesEntry {
    return WorkspaceState_TimeRangesEntry.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<WorkspaceState_TimeRangesEntry>, I>>(
    object: I,
  ): WorkspaceState_TimeRangesEntry {
    const message = createBaseWorkspaceState_TimeRangesEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? SavedTimeRange.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBaseWorkspaceState_AnalysesEntry(): WorkspaceState_AnalysesEntry {
  return { key: '', value: undefined }
}

export const WorkspaceState_AnalysesEntry = {
  encode(
    message: WorkspaceState_AnalysesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      Analysis.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WorkspaceState_AnalysesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState_AnalysesEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = Analysis.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState_AnalysesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value) ? Analysis.fromJSON(object.value) : undefined,
    }
  },

  toJSON(message: WorkspaceState_AnalysesEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = Analysis.toJSON(message.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState_AnalysesEntry>, I>>(
    base?: I,
  ): WorkspaceState_AnalysesEntry {
    return WorkspaceState_AnalysesEntry.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<WorkspaceState_AnalysesEntry>, I>>(
    object: I,
  ): WorkspaceState_AnalysesEntry {
    const message = createBaseWorkspaceState_AnalysesEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? Analysis.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBaseWorkspaceState_SegmentationsEntry(): WorkspaceState_SegmentationsEntry {
  return { key: '', value: undefined }
}

export const WorkspaceState_SegmentationsEntry = {
  encode(
    message: WorkspaceState_SegmentationsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      SavedSegmentation.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WorkspaceState_SegmentationsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState_SegmentationsEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = SavedSegmentation.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState_SegmentationsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? SavedSegmentation.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: WorkspaceState_SegmentationsEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = SavedSegmentation.toJSON(message.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState_SegmentationsEntry>, I>>(
    base?: I,
  ): WorkspaceState_SegmentationsEntry {
    return WorkspaceState_SegmentationsEntry.fromPartial(base ?? ({} as any))
  },
  fromPartial<
    I extends Exact<DeepPartial<WorkspaceState_SegmentationsEntry>, I>,
  >(object: I): WorkspaceState_SegmentationsEntry {
    const message = createBaseWorkspaceState_SegmentationsEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? SavedSegmentation.fromPartial(object.value)
        : undefined
    return message
  },
}

function createBaseWorkspaceState_ExportsEntry(): WorkspaceState_ExportsEntry {
  return { key: '', value: undefined }
}

export const WorkspaceState_ExportsEntry = {
  encode(
    message: WorkspaceState_ExportsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key)
    }
    if (message.value !== undefined) {
      ExportedAnalysis.encode(message.value, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WorkspaceState_ExportsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseWorkspaceState_ExportsEntry()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.key = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.value = ExportedAnalysis.decode(reader, reader.uint32())
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): WorkspaceState_ExportsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? ExportedAnalysis.fromJSON(object.value)
        : undefined,
    }
  },

  toJSON(message: WorkspaceState_ExportsEntry): unknown {
    const obj: any = {}
    if (message.key !== '') {
      obj.key = message.key
    }
    if (message.value !== undefined) {
      obj.value = ExportedAnalysis.toJSON(message.value)
    }
    return obj
  },

  create<I extends Exact<DeepPartial<WorkspaceState_ExportsEntry>, I>>(
    base?: I,
  ): WorkspaceState_ExportsEntry {
    return WorkspaceState_ExportsEntry.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<WorkspaceState_ExportsEntry>, I>>(
    object: I,
  ): WorkspaceState_ExportsEntry {
    const message = createBaseWorkspaceState_ExportsEntry()
    message.key = object.key ?? ''
    message.value =
      object.value !== undefined && object.value !== null
        ? ExportedAnalysis.fromPartial(object.value)
        : undefined
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
