import { useEffect, useRef } from 'react'
import { addMinutes, subMinutes } from 'date-fns'
import { anomalyDisplayTimeRange, WithAnomalyProps } from 'src/types'
import { useModel } from 'models/api'
import useTimeRange, { TimeRangeProvider } from 'src/contexts/timeRange'
import { CursorProvider } from 'src/contexts/cursor'
import { FilterProvider } from 'src/contexts/filter'
import { ModelAnomaliesProvider } from 'src/contexts/modelAnomalies'
import { Anomaly, GqlFetchModel_AnomalyModel_Fragment } from 'src/services'
import {
  OutputTagAndPrediction,
  AnomalyScore,
  InputTags,
} from 'pages/site/models'
import { Banner, Button, Spinner } from 'src/components/ui'

interface ContentProps {
  anomaly: Anomaly
  model: GqlFetchModel_AnomalyModel_Fragment
}

function Content({ model, anomaly }: ContentProps): JSX.Element {
  const { id } = anomaly
  const from = anomaly.start.valueOf()
  const to = anomaly.end ? anomaly.end.valueOf() : Date.now()
  const { setTimeRange } = useTimeRange()

  const prevId = useRef(id)
  useEffect(() => {
    if (id !== prevId.current) {
      setTimeRange({
        from: subMinutes(from, 30).valueOf(),
        to: addMinutes(to, 30).valueOf(),
      })
    }
    prevId.current = id
  }, [id, from, to, setTimeRange])

  return (
    <>
      <OutputTagAndPrediction model={model} anomaly={anomaly} />
      <AnomalyScore model={model} open noDesc />
      <InputTags model={model} anomaly={anomaly} />
    </>
  )
}

export function Details({ anomaly }: WithAnomalyProps): JSX.Element | null {
  const {
    data: model,
    isLoading,
    isError,
    refetch,
  } = useModel(anomaly.model.id)

  if (isLoading) {
    return <Spinner />
  }

  if (isError || !model || model.__typename !== 'AnomalyModel') {
    return (
      <Banner
        variant="error"
        rightComponent={
          <Button
            variant="primary"
            title="Try again"
            onClick={() => refetch()}
          />
        }
        className="col-span-2"
      >
        Model can not be found or is not an AnomalyModel
      </Banner>
    )
  }

  const { from, to } = anomalyDisplayTimeRange({
    from: anomaly.start.valueOf(),
    to: anomaly.end ? anomaly.end.valueOf() : undefined,
    inProgress: anomaly.inProgress,
  })

  return (
    <TimeRangeProvider
      defaultTimeRange={{
        from: subMinutes(from, 30).valueOf(),
        to: addMinutes(to, 30).valueOf(),
      }}
    >
      <CursorProvider>
        <FilterProvider condition={model.anomalyGenerationFilter}>
          <ModelAnomaliesProvider modelId={model.id}>
            <Content model={model} anomaly={anomaly} />
          </ModelAnomaliesProvider>
        </FilterProvider>
      </CursorProvider>
    </TimeRangeProvider>
  )
}
