import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorDisplay } from 'pages/app'
import { Button, Icon, Spinner, Text } from 'src/components/ui'
import {
  useOpcConnection,
  useOpcConnectionSubscriptions,
} from './opc-ua-connection.api'

export function OpcUaSubscriptionsTab(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId)
    throw new Error('Missing orgId, siteId, gatewayId or connectionId')

  const connectionQuery = useOpcConnection(siteId, gatewayId, connectionId)
  const subscriptinsQuery = useOpcConnectionSubscriptions(
    gatewayId,
    connectionId,
  )

  const navigate = useNavigate()

  if (connectionQuery.isLoading || subscriptinsQuery.isLoading)
    return <Spinner />

  if (connectionQuery.isError || subscriptinsQuery.isError) {
    const errorQuery = connectionQuery.isError
      ? connectionQuery
      : subscriptinsQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Failed to load OPC-UA connection or subscriptions"
        action={errorQuery.refetch}
      />
    )
  }

  return (
    <div className="flex flex-col gap-m py-s">
      {subscriptinsQuery.data.length > 0 ? (
        <Text>
          Active OPC-UA subscriptions under connection:{' '}
          {connectionQuery.data.name ?? connectionQuery.data.id}
        </Text>
      ) : (
        <Text className="italic">
          There are no active OPC-UA subscriptions under connection:{' '}
          {connectionQuery.data.name ?? connectionQuery.data.id}
        </Text>
      )}
      <div className="flex flex-wrap gap-m">
        {subscriptinsQuery.data.map(s => (
          <div
            className="flex w-[570px] rounded-2xs border border-solid border-grey-disabled p-m pr-s"
            key={s.id}
          >
            <Icon
              icon={light('cloud-arrow-up')}
              className="mr-s !h-[30px] !w-[36px] text-grey-disabled"
            />
            <div className="flex w-full items-start justify-between">
              <div className="flex items-center gap-m">
                <div>
                  <Text variant="description">Subscription ID</Text>
                  <Text variant="content" bold className="mt-2xs">
                    {s.id}
                  </Text>
                </div>
                <div>
                  <Text variant="description">Nodes</Text>
                  <Text variant="content" bold className="mt-2xs">
                    {s.nodes?.totalCount ?? 'N/A'}
                  </Text>
                </div>
              </div>
              <div className="flex h-full items-center">
                <Button
                  variant={'primary'}
                  title="View Details"
                  onClick={() =>
                    navigate(
                      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${connectionId}/subscriptions/${s.id}`,
                    )
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
