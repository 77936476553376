import { datadogRum } from '@datadog/browser-rum'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { AddChartType } from '../../../trend.types'

type Props = {
  disabled?: boolean
  onClick: (type: AddChartType) => void
}

export function AddChartButton({ disabled, onClick }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useClickOutside(dropdownRef, () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  return (
    <div ref={dropdownRef} className="relative">
      <button
        disabled={disabled}
        className={classNames(
          'flex cursor-pointer items-center rounded-2xs border-0 bg-green-primary p-0 text-white transition disabled:bg-grey-disabled disabled:cursor-default',
          isOpen ? 'bg-green-hover' : 'hover:bg-green-hover',
        )}
        onClick={() => onClick(AddChartType.Single)}
      >
        <Text className="p-xs" bold>
          Add
        </Text>
        <div className="h-s w-px bg-white" />
        <Icon
          icon={regular('chevron-down')}
          className="p-xs"
          onClick={e => {
            e.stopPropagation()
            if (!isOpen)
              datadogRum.addAction('Trend chart add multiple selector opened')
            setIsOpen(!isOpen)
          }}
        />
      </button>
      {isOpen && (
        <div
          className="absolute bottom-[calc(100%+4px)] right-0 flex min-w-[178px] flex-col whitespace-nowrap rounded-2xs border border-solid border-green-primary bg-white text-left text-black shadow-dropdown"
          style={{ zIndex: zIndex.modal + 1 }}
        >
          <div
            className="cursor-pointer px-xs pb-2xs pt-xs transition-all hover:bg-grey-50"
            onClick={e => {
              e.stopPropagation()
              onClick(AddChartType.Multiple)
            }}
          >
            <Text bold>Add multiple charts</Text>
          </div>
          <div
            className="cursor-pointer px-xs pb-xs pt-2xs transition-all hover:bg-grey-50"
            onClick={e => {
              e.stopPropagation()
              onClick(AddChartType.Single)
            }}
          >
            <Text bold>Add single chart</Text>
          </div>
        </div>
      )}
    </div>
  )
}
