// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.26.1
// source: public_export.proto

/* eslint-disable */
import _m0 from 'protobufjs/minimal'
import { Meta } from './common'

export const protobufPackage = 'types.v1'

export interface ExportedAnalysis {
  id: string
  meta: Meta | undefined
  title: string
  url: string
  /** TODO(jonathan): replace with analysis_ref (if we ever make one) */
  analysisId: string
  encrypted: boolean
}

function createBaseExportedAnalysis(): ExportedAnalysis {
  return {
    id: '',
    meta: undefined,
    title: '',
    url: '',
    analysisId: '',
    encrypted: false,
  }
}

export const ExportedAnalysis = {
  encode(
    message: ExportedAnalysis,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id)
    }
    if (message.meta !== undefined) {
      Meta.encode(message.meta, writer.uint32(18).fork()).ldelim()
    }
    if (message.title !== '') {
      writer.uint32(26).string(message.title)
    }
    if (message.url !== '') {
      writer.uint32(34).string(message.url)
    }
    if (message.analysisId !== '') {
      writer.uint32(42).string(message.analysisId)
    }
    if (message.encrypted !== false) {
      writer.uint32(48).bool(message.encrypted)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExportedAnalysis {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = createBaseExportedAnalysis()
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break
          }

          message.id = reader.string()
          continue
        case 2:
          if (tag !== 18) {
            break
          }

          message.meta = Meta.decode(reader, reader.uint32())
          continue
        case 3:
          if (tag !== 26) {
            break
          }

          message.title = reader.string()
          continue
        case 4:
          if (tag !== 34) {
            break
          }

          message.url = reader.string()
          continue
        case 5:
          if (tag !== 42) {
            break
          }

          message.analysisId = reader.string()
          continue
        case 6:
          if (tag !== 48) {
            break
          }

          message.encrypted = reader.bool()
          continue
      }
      if ((tag & 7) === 4 || tag === 0) {
        break
      }
      reader.skipType(tag & 7)
    }
    return message
  },

  fromJSON(object: any): ExportedAnalysis {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      meta: isSet(object.meta) ? Meta.fromJSON(object.meta) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : '',
      url: isSet(object.url) ? globalThis.String(object.url) : '',
      analysisId: isSet(object.analysisId)
        ? globalThis.String(object.analysisId)
        : '',
      encrypted: isSet(object.encrypted)
        ? globalThis.Boolean(object.encrypted)
        : false,
    }
  },

  toJSON(message: ExportedAnalysis): unknown {
    const obj: any = {}
    if (message.id !== '') {
      obj.id = message.id
    }
    if (message.meta !== undefined) {
      obj.meta = Meta.toJSON(message.meta)
    }
    if (message.title !== '') {
      obj.title = message.title
    }
    if (message.url !== '') {
      obj.url = message.url
    }
    if (message.analysisId !== '') {
      obj.analysisId = message.analysisId
    }
    if (message.encrypted !== false) {
      obj.encrypted = message.encrypted
    }
    return obj
  },

  create<I extends Exact<DeepPartial<ExportedAnalysis>, I>>(
    base?: I,
  ): ExportedAnalysis {
    return ExportedAnalysis.fromPartial(base ?? ({} as any))
  },
  fromPartial<I extends Exact<DeepPartial<ExportedAnalysis>, I>>(
    object: I,
  ): ExportedAnalysis {
    const message = createBaseExportedAnalysis()
    message.id = object.id ?? ''
    message.meta =
      object.meta !== undefined && object.meta !== null
        ? Meta.fromPartial(object.meta)
        : undefined
    message.title = object.title ?? ''
    message.url = object.url ?? ''
    message.analysisId = object.analysisId ?? ''
    message.encrypted = object.encrypted ?? false
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string; value: unknown }
  ? { $case: T['$case']; value?: DeepPartial<T['value']> }
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

type KeysOfUnion<T> = T extends T ? keyof T : never
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & {
      [K in Exclude<keyof I, KeysOfUnion<P>>]: never
    }

function isSet(value: any): boolean {
  return value !== null && value !== undefined
}
