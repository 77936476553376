import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Text } from 'src/components/ui'
import { Analysis } from 'src/dex/analysis'

type Props = {
  analysis: Analysis
}

export function AnalysisCard({ analysis }: Props): JSX.Element {
  const navigate = useNavigate()
  return (
    <>
      <div
        onClick={() => {
          // TODO: Navigate to analysis details page
          //   navigate(`analysis/${analysis.id}`)
        }}
        className="flex w-[570px] cursor-pointer rounded-2xs border border-solid border-grey-disabled p-m pr-s transition duration-300 hover:shadow-button"
        key={analysis.id}
      >
        <Icon
          icon={light('chart-mixed')}
          className="mr-s !h-[30px] !w-[36px] text-grey-disabled"
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <div className="flex w-full items-start justify-between">
            <div>
              <Text variant="description">Analysis</Text>
              <Text variant="content" bold className="mb-xs mt-2xs">
                {analysis.label}
              </Text>
              <Text variant="small" className="mt-s">
                Description
              </Text>
              <Text
                variant="description"
                bold
                className="mt-2xs line-clamp-3 min-h-xl"
              >
                {analysis.description || '/'}
              </Text>
            </div>
            <div>
              <Button
                variant={'secondary'}
                title="Copy Analysis"
                className="mb-xs w-full"
                onClick={e => {
                  e.stopPropagation()
                  console.log('Copy analysis')
                }}
              />
              <Button
                variant={'secondary'}
                buttonColor="danger"
                title="Delete Analysis"
                className="w-full"
                onClick={e => {
                  e.stopPropagation()
                  console.log('Delete analysis')
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
