import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useRef } from 'react'
import { useDrop } from 'react-dnd'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon, Text } from 'src/components/ui'
import { nextChartId, removeSeries, useCharts } from 'src/contexts/charts'
import { ChartOptions, ChartType } from 'src/types/chartTypes'
import { useTrendDnd } from '../../trend.state'
import { DnDItem } from '../../trend.types'
import { datadogRum } from '@datadog/browser-rum'

export function Dropzone(): JSX.Element {
  const { isDragging } = useTrendDnd()
  const { addChart, setChart, removeChart, charts } = useCharts()
  const chartRef = useRef(charts)

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: 'legend-row',
    drop: handleDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  // Update the chartRef when the charts change
  // To have valid id for the next chart
  useEffect(() => {
    chartRef.current = charts
  }, [charts])

  function handleDrop(props: DnDItem): void {
    const newId = nextChartId(chartRef.current)
    const chart: ChartOptions = {
      id: newId,
      data: [props.series],
      type: ChartType.TimeSeries,
    }
    addChart(chart)
    // Remove item from the source chart
    const sourceChart = chartRef.current.find(c => c.id === props.sourceChartId)
    if (sourceChart) {
      const newSourceChart = removeSeries(sourceChart, props.series)
      if (newSourceChart.data.length === 0) removeChart(sourceChart.id)
      else setChart(sourceChart.id, newSourceChart)
    }

    datadogRum.addAction('Series dragged to new chart', {
      sourceChart: props.sourceChartId,
      draggedSeries: props.series,
    })

    // Scroll to the new chart
    // Timeout is added to allow the layout to re-render first
    setTimeout(() => {
      const newChart = document.getElementById(`chart-${newId}`)
      if (newChart) {
        newChart.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  const isActive = canDrop && isOver

  return (
    <>
      <AnimatePresence>
        {isDragging && (
          <motion.div
            key="dropzone"
            id="dropzone"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0, dur: 0.1 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{
              opacity: { duration: 0.3 },
              y: { duration: 0.3 },
            }}
            className={classNames(
              'absolute bottom-0 right-10 z-[1] rounded- select-none px-s',
            )}
          >
            <div
              ref={dropRef}
              className={classNames(
                'flex size-[110px] items-center justify-center gap-2xs rounded-full border border-dashed border-border-selected p-xs text-center text-text-interactive transition',
                isActive
                  ? 'bg-brand-primary-light'
                  : 'bg-brand-primary-lightest',
              )}
            >
              <Icon icon={regular('circle-plus')} />
              <Text bold>New Chart</Text>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
